<!-- <app-navbar></app-navbar> -->
<div class=" d-flex container-fuild flex-row  justify-content-center align-items-center h-100">

    <router-outlet></router-outlet>
    <!-- <button class="btn btn-block" (click)="switch()">switch</button> -->



</div>
<!-- <div class="float shadow" (click)="navigate()">
    <i class="fa fa-th-large my-float" title="layout"></i>
</div> -->