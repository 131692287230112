import { Constants } from './../Constants/app.constants';
import { SocketService } from 'src/app/service/socket.service';
import { Injectable } from '@angular/core';
import Peer from 'peerjs';
import { ThrowStmt } from '@angular/compiler';
@Injectable({
  providedIn: 'root'
})
export class PeerService {

  public partnerMic: boolean;
  public partnerVideo: boolean;
  public userMic: boolean;
  public userVideo: boolean;
  public userName: string;
  public fallback: string;

  peer: Peer;
  public roomId;
  myStream: MediaStream;
  myPartner: MediaStream;
  myEl: HTMLMediaElement;
  partnerEl: HTMLMediaElement;
  public partnerConnected: boolean;
  mainVid: HTMLMediaElement;
  constructor(private socktSerivce: SocketService) {
    this.userName = '';
    this.partnerConnected = false;
    this.partnerMic = true;
    this.partnerVideo = true;
    this.userVideo = true;
    this.userMic = true;
  }

  async createPeer(roomId): Promise<void> {
    this.peer = new Peer(undefined, {
      path: "/peerjs",
      host: Constants.PEER_SERVER,
      port: Constants.PEER_PORT,
    });
    this.roomId = roomId;
    this.peer.on('open', (id) => {
      console.log(id, roomId);
      this.socktSerivce.socket.emit(Constants.SOCKET_JOIN_ROOM, roomId, id, this.socktSerivce.userName, this.socktSerivce.profession);
      this.wait();
    });
  }
  setElements(myEl: HTMLMediaElement, partnerEl: HTMLMediaElement): void {
    this.myEl = myEl;
    this.partnerEl = partnerEl;
    try {
      this.partnerEl.srcObject = this.myPartner;
      this.myEl.srcObject = this.myStream;
      this.myEl.muted = true;
      this.myEl.play();
      this.partnerEl.play();
    } catch (err) { console.log(err) }
  }
  async init(roomid: string, config: any): Promise<void> {
    try {
      this.userName = config.name;
      this.getMedia(config);
    } catch (e) {
      this.handleError(e);
    }
    await this.createPeer(roomid);
  }

  getMedia(config): void {
    navigator.getUserMedia({
      audio: { deviceId: { exact: config.microphone.deviceId } },
      video: { deviceId: { exact: config.camera.deviceId } }
    }, (stream) => {
      this.userVideo = true;
      this.userMic = true;
      this.handleSuccess(stream);
      this.joinSound();
    }, (error) => {
      this.handleError(error);
    });
  }
  handleSuccess(stream: MediaStream): void {
    this.myStream = stream;
    this.myEl.srcObject = stream;
    this.myEl.muted = true;
  }

  handleError(error: any): void {

    this.errorMsg(`getUserMedia error: ${error.name}`, error);
  }

  errorMsg(msg: string, error?: any): void {
    console.log({ msg, error });
  }



  call(partnerId: string): void {
    setTimeout(() => {
      const call = this.peer.call(partnerId, this.myStream);
      call.on('stream', (stream) => {
        try {
          console.log('stream Recieved');
          this.joinSound();
          this.partnerConnected = true;
          this.partnerEl.srcObject = stream;
          this.partnerVideo = true;
          this.partnerMic = true;
          this.myPartner = stream;
          // this.socktSerivce.socket.emit(Constants.SOCKET_SEND_NAME,this.roomId, this.socktSerivce.userName);
        } catch (err) { console.log(err); }
      });
    }, 900);

  }

  wait(): void {
    this.peer.on('call', (call) => {
      call.answer(this.myStream);
      call.on('stream', (stream) => {
        try {
          console.log('Wait', stream.id);
          this.joinSound();
          this.partnerConnected = true;
          this.partnerEl.srcObject = stream;
          this.myPartner = stream;
          this.partnerVideo = true;
          this.partnerMic = true;
        } catch (err) { console.log(err); }
      });
    });
  }

  playStop(): void {
    this.buttonPressSound();
    const enabled = this.myStream.getVideoTracks()[0].enabled;
    if (enabled) {
      this.myStream.getVideoTracks()[0].enabled = false;
    } else {
      this.myStream.getVideoTracks()[0].enabled = true;
    }
  }

  muteUnmute(): void {
    this.buttonPressSound();
    const enabled = this.myStream.getAudioTracks()[0].enabled;
    if (enabled) {
      this.myStream.getAudioTracks()[0].enabled = false;
    } else {
      this.myStream.getAudioTracks()[0].enabled = true;
    }
  }

  playStopPartner(): void {
    this.buttonPressSound();
    const enabled = this.myPartner.getVideoTracks()[0].enabled;
    if (enabled) {
      this.myPartner.getVideoTracks()[0].enabled = false;
    } else {
      this.myPartner.getVideoTracks()[0].enabled = true;
    }
  }

  muteUnmutePartner(): void {
    this.buttonPressSound();
    const enabled = this.myPartner.getAudioTracks()[0].enabled;
    if (enabled) {
      this.myPartner.getAudioTracks()[0].enabled = false;
    } else {
      this.myPartner.getAudioTracks()[0].enabled = true;
    }
  }
  reArrange(myEl: HTMLMediaElement, partnerEl: HTMLMediaElement): void {
    this.myEl = myEl;

    this.partnerEl = partnerEl;
    this.partnerEl.srcObject = this.myPartner;
    this.myEl.srcObject = this.myStream;
    this.myEl.muted = true;
    this.myEl.play();
    this.partnerEl.play();
  }

  buttonPressSound(): void {
    const audio = new Audio();
    audio.src = '../assets/button.wav';
    audio.load();
    audio.play();
  }

  joinSound(): void {
    const audio = new Audio();
    audio.src = '../assets/join.wav';
    audio.load();
    audio.play();
  }

  refreshPage(): void {
    window.location.reload();
  }

  fallBack(): void {
    this.socktSerivce.socket.emit(Constants.SOCKET_END_CALL);
    window.location.reload();
  }

}
