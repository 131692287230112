import { MeetComponent } from './home/meet/meet.component';
import { HomeComponent } from './home/home/home.component';
import { HomeModule } from './home/home.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverlapComponent } from './home/layout/overlap/overlap.component';
import { SidelayoutComponent } from './home/layout/sidelayout/sidelayout.component';
import { ChatComponent } from './chat/chat.component'; // Add Chat Component

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home/:id',
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'overlap', pathMatch: 'full' },
      { path: 'side', component: SidelayoutComponent },
      { path: 'overlap', component: OverlapComponent }
    ]
  },
  { path: 'chat', component: ChatComponent }, // Route for AI Chat


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ relativeLinkResolution: 'legacy' }),
   HomeModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
