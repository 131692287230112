<div class="row d-flex justify-content-center" style="margin-right: 0px; margin-left: 0px; margin-top: 5%;">


  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="containerRelative shadow rounded">
      <video id="my-video" class="videoElement " autoplay playsinline muted="muted">

      </video>
      <div class="nameControlBar">
        <div class="nameControlDiv">
          <div>
            <div class="controlNameHover nameHolder"><span class="d-inline-block text-truncate"
                style="max-width: 70px;"> {{socketService.userName}} </span> <span class="logged-in">●</span></div>
          </div>
        </div>
      </div>
      <div class="player-buttons">

        <div class="controlDiv">

          <div>
            <i *ngIf="peerService.userVideo" (click)="stopVideo()" class="fa fa-video m-1 controlItemHoverOn"></i>
            <i *ngIf="!peerService.userVideo" (click)="stopVideo()" class="fa fa-video-slash m-1 controlItemHover"></i>

            <i *ngIf="peerService.userMic" (click)="muteVideo()"
              class="fas fa-microphone  m-1 controlItemHoverOn microphone"></i>
            <i *ngIf="!peerService.userMic" (click)="muteVideo()"
              class="fa fa-microphone-slash m-1 controlItemHover microphone"></i>
            <i (click)="peerService.fallBack()" class="fa fa-phone-slash m-1  controlItemHoverPowerOn"
              style="text-align: center;"></i>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="containerRelative shadow rounded">

      <ng-container *ngIf="!peerService.partnerConnected">

        <div class=" waitingState text-center">
          <img src="../../../../assets/avatar.png" style="width:40%" />
          <div class="text-center">
            <small class="text-white">Waiting for Connection</small>
          </div>
        </div>

      </ng-container>
      <video id="partner-video" muted="muted" class="videoElement" [class.displayNone]="!peerService.partnerConnected"
        [class.displayShow]="peerService.partnerConnected" autoplay playsinline>

      </video>
      <div class="nameControlBar" *ngIf="peerService.partnerConnected">
        <div class="nameControlDiv" *ngIf="peerService.partnerConnected">
          <div>
            <div class="controlNameHover nameHolder"><span class="d-inline-block text-truncate"
                style="max-width: 70px;"> {{socketService.partnerName}} </span> <span class="logged-in">●</span></div>
          </div>
        </div>
      </div>
      <div class="player-buttons" *ngIf="peerService.partnerConnected">

        <div class="controlDiv" *ngIf="peerService.partnerConnected">

          <div>
            <i *ngIf="peerService.partnerVideo" (click)="stopVideoPartner()"
              class="fa fa-video m-1 controlItemHoverOn"></i>
            <i *ngIf="!peerService.partnerVideo" (click)="stopVideoPartner()"
              class="fa fa-video-slash m-1  controlItemHover"></i>
            <i *ngIf="peerService.partnerMic" (click)="muteVideoPartner()"
              class="fas fa-microphone  m-1 controlItemHoverOn microphone"></i>
            <i *ngIf="!peerService.partnerMic" (click)="muteVideoPartner()"
              class="fa fa-microphone-slash m-1 controlItemHover microphone"></i>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>