import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  template: `<nav class="navbar navbar-dark bd-navbar">
    <div class="container">
  <a class="navbar-brand" href="#">Craign</a>
  <div class="form-inline my-2 my-lg-0"><i class="fa fa-th-large navIcon" (click)="navigate()"></i></div>
  </div>
</nav>`,
  styles: [`.bd-navbar {
    background-color: #375a7f;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 10%);
}
.navIcon{
    font-size: 30px;
    color: white;
    cursor:pointer;
}
`]
})
export class NavbarComponent implements OnInit {
layout: boolean;
  constructor(private router: Router, private route: ActivatedRoute) {this.layout = true; }

  ngOnInit(): void {

  }
  navigate(): void{
    if(this.layout){
      this.router.navigate(['./overlap'], {relativeTo: this.route});
    }else{
      this.router.navigate(['./side'], {relativeTo: this.route});
    }
    this.layout = !this.layout;
  }
}
