import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as socketio from 'socket.io-client';
import { Constants } from '../Constants/app.constants';
export enum Event {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect'
}

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  private _socket: any;
  private _userName: string;
  private _partnerName: string;
  private _profession: string;
  private _partnerProfession: string;
  private _topic: string;

  constructor() {

  }

  get topic(): string {
    return this._topic;
  }
  set topic($userName: string) {
    this._topic = $userName;
  }

  get userName(): string {
    return this._userName;
  }
  set userName($userName: string) {
    this._userName = $userName;
  }

  get partnerName(): string {
    return this._partnerName;
  }
  set partnerName($partnerName: string) {
    this._partnerName = $partnerName;
  }


  get profession(): string {
    return this._profession;
  }
  set profession($profession: string) {
    this._profession = $profession;
  }

  get partnerProfession(): string {
    return this._partnerProfession;
  }
  set partnerProfession($partnerProfession: string) {
    this._partnerProfession = $partnerProfession;
  }

  get socket(): any {
    return this._socket;
  }
  set socket($socket: any) {
    this._socket = $socket;
  }

  public initSocket(): void {
    console.log('called');
    this.socket = socketio.io(Constants.SOCKET_SERVER);
  }
  public sendMessage(payload: any, event: string): void {
    this.socket.emit(event, payload);
  }


  public onEvent(event: string): Observable<any> {
    return new Observable<string>(observer => {
      this.socket.on(event, () => observer.next());
    });
  }

  public onData(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('message', (data: any) => observer.next(data));
    });
  }
  public onUserConnect(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on(Constants.SOCKET_USER_CONNECT, (data: any) => observer.next(data));
    });
  }
  public onNameReceive(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on(Constants.SOCKET_RECEIVE_NAME, (data: any) => observer.next(data));
    });
  }
  public onCallReject(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on(Constants.SOCKET_END_CALL, (data: any) => observer.next(data));
    });
  }
  public onNotification(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on(Constants.SOCKET_NOTIFICATION, (data: any) => observer.next(data));
    });
  }
  public getSocketTopic(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on(Constants.SOCKET_TOPIC, (data: any) => observer.next(data));
    });
  }

}
