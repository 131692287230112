<div class="modal-header deviceHeader">
  <h4 class="modal-title">Select Device</h4>

</div>
<div class="modal-body">
  <form [formGroup]="infoForm">

    <div class="form-group">
      <label for="Camera">Camera</label>

      <select formControlName="camera" class="form-control">

        <option value="" disabled selected>Select Your Camera</option>
        <option *ngFor="let c of cameraList" [ngValue]="c">{{ c.label }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="Mic">Microphone</label>
      <select formControlName="microphone" class="form-control">
        <option value="" disabled selected>Select Your Microphone</option>


        <option *ngFor="let m of microphoneList" [ngValue]="m">{{ m.label }}</option>
      </select>
    </div>
  </form>
  <button class="btn btn-block btn-bd-primary " (click)="goto()">Submit</button>
</div>