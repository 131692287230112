import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private apiUrl = '/api/chat'; // Backend API endpoint

  constructor(private http: HttpClient) {}

  sendMessage(
    messages: Array<{ role: string; content: string }>,
    category: string,
    subcategory: string
): Observable<any> {
    return this.http.post(this.apiUrl, { messages, category, subcategory });
}

}
