import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from 'src/app/service/socket.service';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  infoForm: FormGroup;
  microphoneList: Array<any> = [];
  cameraList: Array<any> = [];
  InputText: string;
  constructor(public activeModal: NgbActiveModal,
    private router: Router,
    private socketService: SocketService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    
    this.infoForm = this.setForm();
    this.getDevices();
  }

  async getDevices(): Promise<any> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    devices.forEach(data => {
      console.log(data);
      if (data.kind === 'audioinput') {
        if(data.deviceId === 'default'){ this.infoForm.patchValue({
          microphone: data
       });}
        this.microphoneList.push(data);
      }
      if (data.kind === 'videoinput') {
        if(data.deviceId === 'default'){ this.infoForm.patchValue({
          microphone: data
       });}
        this.cameraList.push(data);
      }
    });
  }
  setForm(): any {
    return this.formBuilder.group({
      camera: ['', Validators.required],
      microphone: ['', Validators.required],
    });
  }

  goto(): void {
    if (this.infoForm.invalid) {
      return;
    }

    console.log(this.infoForm.value);

    this.activeModal.close(this.infoForm.value);
  }
}
