<div class="chat-container">

  <!-- Video Section -->
  <div class="video-section">
      <!-- User Live Video -->
      <div class="user-video-container">
        <div class="name-tag user-name">{{ name }}</div>
        <video
          #userLiveVideo
          class="video user"
          autoplay
          playsinline
        ></video>
        <div class="caption-container user-caption" *ngIf="userSpeech">
          <p>{{ userSpeech }}</p>
        </div>
        <div class="show-conversation-container">
          <button class="show-conversation-button" (click)="scrollToMessages()">Show Conversation</button>
        </div>
        <div class="typing-indicator-container" *ngIf="isTyping || isSpeaking">
          <div class="typing-indicator">
            {{ isSpeaking ? 'Anna is Talking' : 'Anna is Thinking' }}
          </div>
        </div>        
        <div class="mic-container">
          <button class="mic-button" [class.listening]="isListening">
            {{ isListening ? '🎤 Listening...' : '🛑' }}
          </button>
        </div>
      </div>

      <!-- AI Avatar Video -->
      <div class="avatar-video-container">
        <div class="name-tag ai-name">Anna</div>
        <video
          #avatarVideo
          class="video avatar"
          muted
          preload="auto"
          loop
          playsinline
        >
          <source src="assets/avatar.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div class="caption-container ai-caption" *ngIf="currentCaption">
          <p>{{ currentCaption }}</p>
        </div>
      </div>
  </div>

  <!-- Chat Messages -->
  <div class="messages" #messagesContainer>
    <div
      *ngFor="let message of messages"
      [ngClass]="{'you-message': message.sender === 'You', 'ai-message': message.sender === 'AI'}"
    >
      <strong>{{ message.sender }}:</strong> {{ message.message }}
    </div>
    <!-- Display interim speech as the user speaks -->
    <div *ngIf="userSpeech && isListening" class="you-message">
      <strong>You:</strong> {{ userSpeech }}
    </div>
  </div>
</div>
