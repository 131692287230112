import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { MeetComponent } from './meet/meet.component';
import { InfoComponent } from './modal/info/info.component';
import { NameComponent } from './modal/name/name.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './navbar/navbar.component';
import { SidelayoutComponent } from './layout/sidelayout/sidelayout.component';
import { OverlapComponent } from './layout/overlap/overlap.component';
import { DropdownComponent } from '../shared/dropdown/dropdown.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    DropdownComponent,
    HomeComponent,
    MeetComponent,
    InfoComponent,
    NameComponent,
    NavbarComponent,
    SidelayoutComponent,
    OverlapComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    BrowserAnimationsModule
  ],
  entryComponents: [InfoComponent]
})
export class HomeModule { }
