import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ChatService } from '../service/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  @ViewChild('avatarVideo', { static: false }) avatarVideo!: ElementRef<HTMLVideoElement>;
  @ViewChild('userLiveVideo', { static: false }) userLiveVideo!: ElementRef<HTMLVideoElement>;
  @ViewChild('messagesContainer', { static: false }) messagesContainer!: ElementRef<HTMLDivElement>;

  name: string = '';
  profession: string = '';
  messages: { sender: string; message: string }[] = [];
  isTyping: boolean = false;
  isListening: boolean = false;
  recognition: any = null;
  userSpeech: string = ''; // Holds the user's speech
  speechTimeout: any = null;
  isSpeaking: boolean = false;
  voices: SpeechSynthesisVoice[] = [];
  selectedVoice: SpeechSynthesisVoice | null = null;
  currentCaption: string = ''; // Holds the AI's current caption

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private chatService: ChatService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
        this.name = params['name'] || 'Guest';
        const category = params['category'] || 'General'; // Get category from query params
        const subcategory = params['subcategory'] || 'Open Discussion'; // Get subcategory from query params

        if (!params['name'] || !params['category'] || !params['subcategory']) {
            this.router.navigate(['/']); // Redirect if required params are missing
        }

        console.log(`Name: ${this.name}, Category: ${category}, Subcategory: ${subcategory}`);

        // Initialize other components
        this.initializeSpeechRecognition();
        this.startListening();
        this.loadVoices();
        this.startUserLiveVideo();
    });

    // Warm up speech synthesis
    const dummyUtterance = new SpeechSynthesisUtterance(' ');
    window.speechSynthesis.speak(dummyUtterance);
}

  // Start the user's live video
  startUserLiveVideo(): void {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        const videoElement = this.userLiveVideo.nativeElement;
        videoElement.srcObject = stream;
        videoElement.play();
      })
      .catch((err) => {
        console.error('Error accessing user video:', err);
        Swal.fire({
          title: 'Camera Access Denied',
          text: 'Unable to access the camera. Please allow camera access and refresh the page.',
          icon: 'error',
          confirmButtonText: 'Okay'
        });
      });
  }

  // Control video playback when AI starts/stops speaking
  handleVideoPlayback(shouldPlay: boolean, isFirstChunk: boolean = false): void {
    const videoElement = this.avatarVideo.nativeElement;
    if (shouldPlay) {
      if (isFirstChunk) {
        setTimeout(() => {
          videoElement.play().catch((err) => console.error('Error playing video:', err));
        }, 0); // Play without delay for the first chunk
      } else {
        videoElement.play().catch((err) => console.error('Error playing video:', err));
      }
    } else {
      videoElement.pause();
      videoElement.currentTime = 0; // Reset to start
    }
  }

  // Speech recognition setup
  initializeSpeechRecognition(): void {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'en-UK';
      this.recognition.interimResults = true;
      this.recognition.continuous = true;

      this.recognition.onstart = () =>
        this.ngZone.run(() => {
          this.isListening = true;
          console.log('Speech recognition started');
        });

      this.recognition.onresult = (event: any) =>
        this.ngZone.run(() => {
          let transcript = '';
          let words = '';

          for (let i = event.resultIndex; i < event.results.length; ++i) {
            const result = event.results[i];
            transcript += result[0].transcript;

            // Display interim results live
            if (!result.isFinal) {
              words += result[0].transcript + ' ';
            } else {
              words += result[0].transcript + ' ';
            }
          }

          this.userSpeech = transcript; // Update the user's speech
          console.log('User Speech:', transcript);

          if (this.speechTimeout) {
            clearTimeout(this.speechTimeout);
          }
          this.speechTimeout = setTimeout(() => {
            this.stopListening();
            console.log('User stopped speaking, recognition stopped.');
          }, 3000);
        });

      this.recognition.onend = () =>
        this.ngZone.run(() => {
          this.isListening = false;
          console.log('Speech recognition ended');
          if (this.userSpeech.trim()) {
            this.sendMessage(this.userSpeech);
            this.userSpeech = '';
          } else if (!this.isSpeaking) {
            this.startListening();
          }
        });

      this.recognition.onerror = (event: any) =>
        this.ngZone.run(() => {
          console.error('Speech recognition error:', event.error);

          Swal.fire({
            title: 'Error!',
            text: `Speech recognition error: ${event.error}`,
            icon: 'error',
            confirmButtonText: 'Try Again',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.startListening(); // Restart speech recognition on user confirmation
            }
          });
        });
    } else {
      console.error('Speech recognition is not supported in this browser.');
      Swal.fire({
        title: 'Unsupported Feature',
        text: 'Speech recognition is not supported in your browser.',
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  }

  // Load available voices for AI speech synthesis
  loadVoices(): void {
    if ('speechSynthesis' in window) {
      const setDefaultVoice = () => {
        const voices = window.speechSynthesis.getVoices();
  
        // Log available voices and their languages
        voices.forEach((voice) => {
          console.log(`Voice: ${voice.name}, Language: ${voice.lang}`);
        });
  
        // Set default voice
        this.selectedVoice = voices.find(
          (voice) => voice.name === 'Google US English' && voice.lang === 'en-US'
        ) || voices[0];
      };
  
      // Trigger loading voices
      window.speechSynthesis.getVoices();
      setDefaultVoice();
  
      // Listen for voices change
      window.speechSynthesis.onvoiceschanged = setDefaultVoice;
    }
  }
  

  // Start speech recognition listening
  startListening(): void {
    if (this.recognition && !this.isSpeaking) {
      this.recognition.start();
      console.log('Listening started');
    }
  }

  // Stop speech recognition listening
  stopListening(): void {
    if (this.recognition) {
      this.recognition.stop();
      console.log('Listening stopped');
    }
  }

  // Send user message to AI
  sendMessage(message: string): void {
    if (!message.trim()) return;

    console.log('Sending message:', message);
    this.messages.push({ sender: 'You', message });
    this.isTyping = true;

    const messageHistory = this.messages.map((msg) => ({
      role: msg.sender === 'You' ? 'user' : 'assistant',
      content: msg.message,
    }));

    this.stopListening();

    const category = this.route.snapshot.queryParams['category'] || 'General';
    const subcategory = this.route.snapshot.queryParams['subcategory'] || 'Open Discussion';

    this.chatService.sendMessage(messageHistory, category, subcategory).subscribe(
      (response) => {
        const aiResponse = response.response;
        console.log('AI response:', aiResponse);
        this.messages.push({ sender: 'AI', message: aiResponse });
        this.isTyping = false;

        this.speakText(aiResponse);
      },
      (error) => {
        console.error('Error:', error);
        this.isTyping = false;
        this.startListening();
      }
    );
  }

  // Speak AI response using speech synthesis
  speakText(text: string): void {
    if ('speechSynthesis' in window) {
      this.isSpeaking = true;
      const utterances = this.splitTextIntoChunks(text);
      this.speakUtterances(utterances);
    } else {
      console.error('Speech synthesis is not supported in this browser.');
      this.startListening();
    }
  }

  // Split long text into manageable chunks for speech synthesis
  splitTextIntoChunks(text: string): string[] {
    const maxChunkLength = 200; // Adjust as needed
    const sentences =
      text.match(/[^.!?]+[.!?]+|\s*$/g)?.filter((s) => s.trim().length > 0) || [
        text,
      ];
    const chunks: string[] = [];
    let currentChunk = '';

    for (const sentence of sentences) {
      if ((currentChunk + sentence).length <= maxChunkLength) {
        currentChunk += sentence;
      } else {
        if (currentChunk) {
          chunks.push(currentChunk);
        }
        currentChunk = sentence;
      }
    }

    if (currentChunk) {
      chunks.push(currentChunk);
    }

    return chunks;
  }

  typingEffect(text: string): void {
    const words = text.split(' '); // Split text into words
    let currentText = '';
    let wordIndex = 0;
  
    const interval = setInterval(() => {
      if (wordIndex < words.length) {
        currentText += words[wordIndex] + ' '; // Add word with space
        this.ngZone.run(() => {
          this.currentCaption = currentText; // Update caption progressively
        });
        wordIndex++;
      } else {
        clearInterval(interval); // Stop when all words are displayed
      }
    }, 200); // Adjust delay (200ms) between each word
  }

  scrollToMessages(): void {
    if (this.messagesContainer) {
      this.messagesContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }
  

  // Speak each chunk of text sequentially
// Speak each chunk of text sequentially
speakUtterances(utterances: string[], index: number = 0): void {
  if (index < utterances.length) {
      const speech = new SpeechSynthesisUtterance(utterances[index]);
      if (this.selectedVoice) {
          speech.voice = this.selectedVoice;
      }

      speech.rate = 1.0;

      speech.onstart = () => {
          this.ngZone.run(() => {
              // Video plays when speech starts
              this.isSpeaking = true;
              this.typingEffect(utterances[index]);
              this.handleVideoPlayback(true, index === 0);
          });
      };

      speech.onend = () => {
          this.ngZone.run(() => {
              this.currentCaption = '';

              // Pause video between chunks
              this.handleVideoPlayback(false);

              // Delay before moving to the next chunk
              setTimeout(() => {
                  if (index < utterances.length - 1) {
                      // Continue speaking the next chunk
                      this.speakUtterances(utterances, index + 1);
                  } else {
                      // End of speech
                      this.isSpeaking = false;
                      this.startListening();
                  }
              }, 500); // Adjust this delay (e.g., 500ms) for natural pauses
          });
      };

      speech.onerror = (event) => {
          console.error('Speech synthesis error:', event.error);
          this.ngZone.run(() => {
              this.isSpeaking = false;
              this.currentCaption = '';
              this.handleVideoPlayback(false);
              this.startListening();
          });
      };

      window.speechSynthesis.speak(speech);
  } else {
      // No chunks to speak (empty text), ensure we reset states
      this.ngZone.run(() => {
          this.isSpeaking = false;
          this.currentCaption = '';
          this.handleVideoPlayback(false);
          this.startListening();
      });
  }
}

  
  
}
