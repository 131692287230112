<video autoplay muted loop id="myVideo">
    <source src="assets/bg-na.mp4" type="video/mp4" muted>
</video>


<div class="modal-body entryModal">
    <form [formGroup]="infoForm" style="padding: 40px;">
        <div class="form-group text-center">
            <img src="assets/logo.png" alt="debate-z" style="width: 50%;">
        </div>
        <div class="form-group text-center">
            <small>Place where you find like-minded people</small>
        </div>



        <div class="spinner-container">
            <div [ngClass]="{'spinner': true, 'final-selection': isFinalSelection}">
                <!--<div *ngIf="!changingText && spinning" class="spinner-indicator">Spinning...</div>-->
                <div *ngIf="!spinning" class="selected-category">{{ selectedCategory }}</div>
                <div *ngIf="changingText && spinning" class="changing-text">{{ changingText }}</div>
            </div>
            <button class="btn btn-block btn-bd-primary" [disabled]="isButtonDisabled()" (click)="spin()">Spin for a
                debate topic and dive in!</button>
        </div>

        <div class="form-group" style="margin-top: 30px;">

            <input type="text" class="form-control" formControlName="name" aria-describedby="emailHelp"
                placeholder="Enter Username">
        </div>


        <button class="btn btn-block btn-bd-primary " (click)="goto()">Join Now</button>
        <!-- New Debate with AI Button -->
        <button class="btn btn-block btn-bd-primary" (click)="debateWithAI()" style="margin-top: 10px;">
            Debate with AI
        </button>

        <div class="form-group text-center" style="margin: 20px">
            <small>Allowing you to view your opinions, advice or simply take on others on theirs, all in good spirit. As
                who does not like to think theirs are the best?</small>
        </div>


    </form>

</div>